import PropTypes from 'prop-types';

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox as MuiCheckbox,
} from '@mui/material';

/**
 * if you're using this component within the Form component, don't forget to pass type='checkbox' or else the form won't know how to handle the value
 */
function Checkbox({ name, label, error, helperText, ...props }) {
  return (
    <FormControl required error={error} component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
          control={<MuiCheckbox type="checkbox" {...props} />}
          label={label}
          name={name}
        />
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

Checkbox.defaultProps = {
  error: false,
  helperText: '',
};

export default Checkbox;
