import { observer } from 'mobx-react';
import { useState } from 'react';

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { makeStyles } from '@bequestinc/wui';
import { Grid } from '@mui/material';

import { useGlobalContext } from 'hooks';

import { NOT_BILLABLE_CASE_TYPES } from 'utils/constants';

import ChipSelector from 'components/ChipSelector';

const useStyles = makeStyles()({
  textFieldPadded: {
    width: '100%',
  },
});

const AttorneyServicesSelector = ({ name, value, restrictedServices, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const { classes } = useStyles();
  const { services } = useGlobalContext();
  const [servicesByCategory, setServicesByCategory] = useState(services.categorizeServices(value));

  const onChange = category => (_, serviceList) => {
    // Note that `serviceList` is an array here
    const newServices = { ...servicesByCategory };
    newServices[category] = serviceList;
    const newFlatServices = Object.values(newServices).flat();
    setServicesByCategory(newServices);
    setFieldValue(name, newFlatServices);
  };

  if (services.loading) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      {services.categories.map(category => {
        return (
          <Grid item className={classes.textFieldPadded} key={category}>
            <ChipSelector
              {...props}
              options={services.servicesByCategory[category].filter(
                service =>
                  !restrictedServices.includes(services.getServiceId(service)) &&
                  !services.getServiceName(NOT_BILLABLE_CASE_TYPES).includes(service),
              )}
              value={value.filter(val => services.servicesByCategory[category].includes(val))}
              onChange={onChange(category)}
              label={category}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

AttorneyServicesSelector.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  restrictedServices: PropTypes.array,
};

AttorneyServicesSelector.defaultProps = {
  restrictedServices: [],
};

export default observer(AttorneyServicesSelector);
