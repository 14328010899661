import * as Yup from 'yup';

import { DEPENDENT, FIELD_REQUIRED_MESSAGE, PHONE_REGEX } from 'utils/constants';

export const addressSchema = Yup.object().shape({
  country: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  street: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  street2: Yup.string(),
  city: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  state: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  zipCode: Yup.string().required(FIELD_REQUIRED_MESSAGE), // Zip Code validation will be done by the backend
});

export const newMemberSchema = addressSchema.shape({
  firstName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  lastName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, 'Phone number is not valid')
    .required(FIELD_REQUIRED_MESSAGE),
  employerName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  email: Yup.string().email('Please enter a valid email address'),
});

export const newDependentSchema = addressSchema.shape({
  relationshipToMember: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  firstName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  lastName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, 'Phone number is not valid')
    .required(FIELD_REQUIRED_MESSAGE),
  email: Yup.string().email('Please enter a valid email address'),
  dateOfBirth: Yup.date().when('relationshipToMember', {
    is: DEPENDENT,
    then: Yup.date().required(
      'Non-special needs dependents must add their birthday to show they were under 26 when service started',
    ),
  }),
});
