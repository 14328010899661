import { useMutation } from '@tanstack/react-query';

import { findAndUpdateFirm, updateOfficeAttorneys } from 'api';

export const useUpdateOfficeAttorneysMutation = (overrides = {}) =>
  useMutation({
    mutationFn: updateOfficeAttorneys,
    ...overrides,
  });

export const useUpdateFirmMutation = (overrides = {}) =>
  useMutation({
    mutationFn: findAndUpdateFirm,
    ...overrides,
  });
