import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Spacer, makeStyles } from '@bequestinc/wui';
import { Grid, TextField, Typography } from '@mui/material';

import { useGlobalContext } from 'hooks';
import { FirmsQueryKey, useFirmOptionsQuery } from 'hooks/query-hooks/firms/useFirmsQuery';
import {
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
} from 'hooks/query-hooks/offices/useOfficeMutation';
import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

import {
  ACTIVE,
  ADD,
  CAN_EDIT_FEE_SCHEDULE,
  CAN_EDIT_OFFICE_ATTORNEYS_AND_SERVICES,
  CONFIRM,
  INTAKE,
  LANGUAGES,
  ON_HOLD,
  REGISTER,
  UPDATE,
} from 'utils/constants';
import parseErrors from 'utils/errors';
import formatPhoneNumber from 'utils/validators';

import AddressFields from 'components/AddressFields';
import ChipSelector from 'components/ChipSelector';
import PhoneNumberField from 'components/custom_fields/PhoneNumberField';
import Checkbox from 'components/form/Checkbox';
import Form from 'components/form/Form';
import FormAreaWithAlert from 'components/form/FormAreaWithAlert';
import SelectField from 'components/form/SelectField';
import AttorneyServicesSelector from 'components/pages/firm-info/AttorneyServicesSelector';
import OfficeFormButtons from 'components/pages/firm-info/OfficeFormButtons';
import { officeSchema } from 'components/pages/firm-info/schema';

const useStyles = makeStyles()({
  textField: {
    width: '100%',
  },
  shortTextField: {
    width: '95%',
  },
  card: {
    padding: '20px',
  },
  underReviewText: {
    marginTop: '10px',
    marginLeft: '20px',
  },
});

const OfficeInfoForm = ({ office, handleSubmit, canUpdate, action, afterSubmitAction }) => {
  const { classes } = useStyles();
  const { services } = useGlobalContext();
  const [editing, setEditing] = useState(action !== UPDATE);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState('');
  const [restrictedServiceOptions] = useState(services.serviceOptions);
  const [restrictedServiceTypeIds, setRestrictedServiceTypeIds] = useState(
    office.restrictedServiceTypeIds,
  );

  const { data: user } = useCurrentUserQuery();
  const { data: firmOptions = { specialServices: [] } } = useFirmOptionsQuery();

  const addToCache = data =>
    queryClient.setQueryData(FirmsQueryKey.concat(office.firm), oldData => ({
      ...oldData,
      offices: oldData.offices.concat(data),
    }));

  const updateCache = data =>
    queryClient.setQueryData(FirmsQueryKey.concat(office.firm), oldData => ({
      ...oldData,
      offices: oldData.offices.map(oldOffice => (oldOffice.id === data.id ? data : oldOffice)),
    }));

  const canPanelEditAttorneysAndServices = user.isPanelUser
    ? user.groups.includes(CAN_EDIT_OFFICE_ATTORNEYS_AND_SERVICES)
    : true;

  const parseOfficeError = response => {
    const isBadRequestStatusCode = response && response.status === 400;
    const isAddressError =
      response && typeof response.data === 'object' && 'latitude' in response.data;
    if (isBadRequestStatusCode && isAddressError) {
      return 'Registering office failed! Check your address information and try again.';
    }
    return parseErrors(response);
  };

  const createOfficeMutation = useCreateOfficeMutation({
    onSuccess: data => {
      addToCache(data);
      afterSubmitAction();
    },
    onError: error => setErrorMessage(parseOfficeError(error.response)),
  });

  const updateOfficeMutation = useUpdateOfficeMutation({
    onSuccess: data => {
      setEditing(false);
      updateCache(data);
      afterSubmitAction();
    },
    onError: error => setErrorMessage(parseOfficeError(error.response)),
  });

  const isSubmitting = [createOfficeMutation, updateOfficeMutation].some(
    mutation => mutation.isLoading,
  );

  const handleFormSubmit = formValues => {
    const values = { ...formValues };
    if (values.serviceTypes.length > 0) {
      values.serviceTypeIds = services.getServiceId(values.serviceTypes);
    }

    if (values.specialServices.length > 0) {
      values.specialServices = values.specialServices.map(service => service[0]);
    }

    if (values.restrictedServiceTypeIds.length > 0) {
      values.restrictedServiceTypeIds = values.restrictedServiceTypeIds.map(service => service[0]);
    }

    if (handleSubmit) {
      handleSubmit(values);
    } else if (action === ADD) {
      createOfficeMutation.mutateAsync({ ...values });
    } else {
      updateOfficeMutation.mutateAsync({ ...values });
    }
  };

  return (
    <Form
      initialValues={{
        status: office.status || ACTIVE,
        id: office.id || null,
        firm: office.firm || null,
        country: office.country || '',
        street: office.street || '',
        street2: office.street2 || '',
        city: office.city || '',
        state: office.state || '',
        zipCode: office.zipCode || '',
        phone: formatPhoneNumber(office.phone) || '',
        phoneExtension: office.phoneExtension || '',
        email: office.email || '',
        languages: office.languages || [],
        numberOfAttorneys:
          action === ADD || action === REGISTER ? 1 : office.numberOfAttorneys || '',
        serviceTypes: office.serviceTypes || [],
        specialServices: firmOptions?.specialServices.filter(service =>
          office.specialServices?.includes(service[0]),
        ),
        restrictedServiceTypeIds: restrictedServiceOptions.filter(service =>
          office.restrictedServiceTypeIds?.includes(service[0]),
        ),
        fieldsUnderReview: office.fieldsUnderReview,
        engagementScore: [ADD, REGISTER, INTAKE].includes(action) ? 5 : office.engagementScore,
        addressInfoConfirmed: false,
        serviceInfoConfirmed: false,
        forConfirmationModal: action === CONFIRM,
      }}
      validationSchema={officeSchema}
      handleFormSubmit={handleFormSubmit}
      shouldReset={false}
      className=""
      validateOnChange
      validateOnBlur
      validateOnMount
      enableReinitialize
      doNotRenderButtons
      initialTouched={{ addressInfoConfirmed: true, serviceInfoConfirmed: true }}
    >
      <Grid container direction="column" spacing={2} className={classes.card}>
        {user.isPanelRepUser && (
          <>
            <Grid item>
              <SelectField
                name="status"
                label="Status"
                disabled={!canUpdate || !editing}
                className={classes.textField}
                menuItems={[ACTIVE, ON_HOLD]}
              />
            </Grid>
            <Grid item>
              <TextField
                name="engagementScore"
                label="Engagement Score"
                className={classes.textField}
                disabled={!canUpdate || !editing || !user.groups.includes(CAN_EDIT_FEE_SCHEDULE)}
                helperText={
                  canUpdate &&
                  editing &&
                  !user.groups.includes(CAN_EDIT_FEE_SCHEDULE) &&
                  'You do not have the proper permissions to edit this field'
                }
              />
            </Grid>
            <Grid item>
              <TextField
                name="numberOfAttorneys"
                label="Number of Attorneys"
                className={classes.textField}
                disabled={!canUpdate || !editing}
              />
            </Grid>
          </>
        )}

        <FormAreaWithAlert active={Boolean(action === CONFIRM)}>
          {office.fieldsUnderReview && Object.keys(office.fieldsUnderReview).length > 0 && (
            <Typography color="text.disabled" className={classes.underReviewText}>
              Cannot edit address while changes are under review by MLP
            </Typography>
          )}
          <AddressFields
            disabled={
              !canUpdate ||
              !editing ||
              (office.fieldsUnderReview && Object.keys(office.fieldsUnderReview).length > 0)
            }
            fieldsUnderReview={office.fieldsUnderReview}
          />
          <Spacer v={10} />
          {action === CONFIRM && (
            <Checkbox
              type="checkbox"
              name="addressInfoConfirmed"
              label="This information is correct"
            />
          )}
        </FormAreaWithAlert>

        <Grid container item direction="row" spacing={2}>
          <Grid item xs={6}>
            <PhoneNumberField
              name="phone"
              label="Phone"
              className={classes.textField}
              disabled={!canUpdate || !editing}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="phoneExtension"
              label="Phone Extension"
              inputProps={{
                maxLength: 6,
              }}
              className={classes.textField}
              disabled={!canUpdate || !editing}
            />
          </Grid>
        </Grid>

        <Grid item>
          <ChipSelector
            name="languages"
            options={LANGUAGES}
            label="Office Staff Languages"
            textFieldStyle={classes.textField}
            disabled={!canUpdate || !editing}
            type="autocomplete"
          />
        </Grid>

        <Grid item>
          <TextField
            name="email"
            label="Email"
            disabled={!canUpdate || !editing}
            className={classes.textField}
          />
        </Grid>

        <FormAreaWithAlert active={Boolean(action === CONFIRM)}>
          <Typography>Practice Areas:</Typography>
          <Spacer v={12} />
          <AttorneyServicesSelector
            name="serviceTypes"
            disabled={!canUpdate || !editing || !canPanelEditAttorneysAndServices}
            restrictedServices={restrictedServiceTypeIds}
          />
          <Spacer v={10} />
          {action === CONFIRM && (
            <Checkbox
              type="checkbox"
              name="serviceInfoConfirmed"
              label="This information is correct"
            />
          )}
        </FormAreaWithAlert>

        <Grid item>
          <ChipSelector
            name="specialServices"
            options={firmOptions.specialServices}
            label="Special Services"
            textFieldStyle={classes.textField}
            disabled={!canUpdate || !editing || !canPanelEditAttorneysAndServices}
            type="autocomplete"
          />
        </Grid>

        {user.isPanelUser && (
          <Grid item>
            <ChipSelector
              name="restrictedServiceTypeIds"
              options={restrictedServiceOptions}
              label="Restricted Services"
              onChange={(_event, _setFieldValue, value) =>
                setRestrictedServiceTypeIds(value.map(option => option[0]))
              }
              textFieldStyle={classes.textField}
              disabled={!canUpdate || !editing || !canPanelEditAttorneysAndServices}
              type="autocomplete"
            />
          </Grid>
        )}

        <Spacer v={24} />
        <OfficeFormButtons
          enabled={canUpdate}
          action={action}
          office={office}
          editing={editing}
          beginEditing={() => {
            setEditing(true);
            updateOfficeMutation.reset();
          }}
          isSubmitting={isSubmitting}
        />

        {(action === ADD || action === UPDATE) && (
          <>
            {updateOfficeMutation.isSuccess && (
              <Typography fontWeight="bold" color="primary">
                Office information successfully updated
              </Typography>
            )}
            <Typography fontWeight="bold" color="error" style={{ whiteSpace: 'pre-line' }}>
              {errorMessage}
            </Typography>
          </>
        )}
      </Grid>
    </Form>
  );
};

OfficeInfoForm.propTypes = {
  office: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  canUpdate: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  afterSubmitAction: PropTypes.func,
};

OfficeInfoForm.defaultProps = {
  handleSubmit: null,
  afterSubmitAction: () => {},
};

export default observer(OfficeInfoForm);
