import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import formatPhoneNumber from 'utils/validators';

function PhoneNumberField({ name, ...props }) {
  const { setFieldValue } = useFormikContext();
  return (
    <TextField
      {...props}
      name={name}
      onChange={e => setFieldValue(name, formatPhoneNumber(e.target.value))}
    />
  );
}

PhoneNumberField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PhoneNumberField;
