import { useMutation } from '@tanstack/react-query';

import { addOffice, changePrimaryOffice, deleteOffice, findAndUpdateOffice } from 'api';

export function useUpdateOfficeMutation(override = {}) {
  return useMutation({
    mutationFn: findAndUpdateOffice,
    ...override,
  });
}

export function useCreateOfficeMutation(override = {}) {
  return useMutation({
    mutationFn: addOffice,
    ...override,
  });
}

export function useDeleteOfficeMutation(override = {}) {
  return useMutation({
    mutationFn: deleteOffice,
    ...override,
  });
}

export function useChangePrimaryOfficeMutation(override = {}) {
  return useMutation({
    mutationFn: changePrimaryOffice,
    ...override,
  });
}
