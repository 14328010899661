import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

import { Modal, Spacer, makeStyles } from '@bequestinc/wui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { FirmsQueryKey } from 'hooks/query-hooks/firms/useFirmsQuery';
import { useDeleteOfficeMutation } from 'hooks/query-hooks/offices/useOfficeMutation';

import parseErrors from 'utils/errors';

const useStyles = makeStyles()({
  root: {
    width: '80%',
    maxWidth: 1000,
    margin: 'auto',
  },
  button: {
    marginTop: '30px',
    marginLeft: '50px',
  },
});

const DeleteOfficeInfoModal = ({ open, onClose, officeId, firmId }) => {
  const { classes } = useStyles();
  const queryClient = useQueryClient();

  const deleteFromCache = () =>
    queryClient.setQueryData(FirmsQueryKey.concat(firmId), oldData => ({
      ...oldData,
      offices: oldData.offices.filter(office => office.id !== officeId),
    }));

  const deleteOfficeMutation = useDeleteOfficeMutation({
    onSuccess: () => {
      onClose();
      deleteFromCache();
    },
  });

  return (
    <Modal title="Remove Office?" open={open} onClose={onClose} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            Once removed, this office location will no longer be viewable to members.
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={onClose} className={classes.button}>
            No, Keep Office
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deleteOfficeMutation.mutateAsync(officeId)}
            className={classes.button}
          >
            Yes, Delete Office
          </Button>
        </Grid>
      </Grid>
      <Spacer v={16} />
      {deleteOfficeMutation.error && (
        <Typography fontWeight="bold" color="error" style={{ whiteSpace: 'pre-line' }}>
          {parseErrors(deleteOfficeMutation.error.response)}
        </Typography>
      )}
    </Modal>
  );
};

DeleteOfficeInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  officeId: PropTypes.number.isRequired,
  firmId: PropTypes.number.isRequired,
};

export default observer(DeleteOfficeInfoModal);
