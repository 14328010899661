import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useState } from 'react';

import PropTypes from 'prop-types';

import { Spacer } from '@bequestinc/wui';
import { Button, Grid, Typography } from '@mui/material';

import { useUpdateOfficeAttorneysMutation } from 'hooks/query-hooks/firms/useFirmsMutation';
import { FirmsQueryKey } from 'hooks/query-hooks/firms/useFirmsQuery';

import parseErrors from 'utils/errors';

import ChipSelector from 'components/ChipSelector';

const AttorneySelector = ({ office }) => {
  const queryClient = useQueryClient();

  const [attorneys, setAttorneys] = useState(office.attorneyInfos.map(atty => atty.name));
  const [error, setError] = useState('');

  const updateCache = data =>
    queryClient.setQueryData(FirmsQueryKey.concat(office.firm), oldData => ({
      ...oldData,
      offices: oldData.offices.map(oldOffice => (oldOffice.id === data.id ? data : oldOffice)),
    }));

  const updateAttorneyMutation = useUpdateOfficeAttorneysMutation({
    onSuccess: data => {
      setError('');
      updateCache(data);
    },
    onError: e => {
      setError(parseErrors(e.response));
    },
  });

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <Typography variant="h6">
            Please select attorneys working at the following address:
          </Typography>
          <Typography>
            {office.street}, {office.street2}
            <br />
            {office.city}, {office.state}, {office.zipCode}, {office.country}
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ChipSelector
            options={office.firmAttorneys.map(atty => atty.name)}
            label="Attorneys"
            value={attorneys}
            onChange={(event, value) => setAttorneys(value)}
          />
        </Grid>
      </Grid>
      <Spacer v={12} />
      <Button
        variant="contained"
        onClick={() =>
          updateAttorneyMutation.mutateAsync({
            id: office.id,
            attorneys: office.firmAttorneys
              .filter(attorney => attorneys.includes(attorney.name))
              .map(attorney => attorney.pk),
          })
        }
      >
        Update
      </Button>
      <Spacer v={16} />
      {updateAttorneyMutation.isSuccess && (
        <Typography fontWeight="bold" color="primary">
          Attorney successfully assigned!
        </Typography>
      )}
      {error && (
        <Typography fontWeight="bold" color="error" style={{ whiteSpace: 'pre-line' }}>
          {error}
        </Typography>
      )}
    </>
  );
};

AttorneySelector.propTypes = {
  office: PropTypes.object.isRequired,
};

export default observer(AttorneySelector);
