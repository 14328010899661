import { makeStyles } from '@bequestinc/wui';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles()({
  skeletonFormText: {
    height: '8vh',
  },
});

const Placeholder = () => {
  const { classes } = useStyles();
  return (
    <>
      {[1, 2, 3, 4, 5].map(n => (
        <Skeleton key={n} animation="wave" className={classes.skeletonFormText} />
      ))}
    </>
  );
};

export default Placeholder;
