import PropTypes from 'prop-types';

import { Spacer, makeStyles } from '@bequestinc/wui';
import { Alert } from '@mui/material';

const useStyles = makeStyles()({
  textFieldPadded: {
    width: '100%',
    marginLeft: 15,
    marginTop: 15,
  },
});

const FormAreaWithAlert = ({ children, active }) => {
  const { classes } = useStyles();

  return (
    <span className={classes.textFieldPadded}>
      {active ? (
        <Alert severity="warning" variant="outlined">
          Please Review
          <Spacer v={10} />
          {children}
        </Alert>
      ) : (
        children
      )}
    </span>
  );
};

FormAreaWithAlert.propTypes = {
  children: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

FormAreaWithAlert.defaultProps = {
  active: false,
};

export default FormAreaWithAlert;
