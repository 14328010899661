import { useQuery } from '@tanstack/react-query';

import getCountries from 'api/address';

import { SIX_HOURS_IN_MILLISECONDS } from 'utils/constants';

export const getCountriesQueryKey = ['get-countries'];

export const useCountriesQuery = (overrides = {}) =>
  useQuery({
    queryKey: getCountriesQueryKey,
    queryFn: getCountries,
    staleTime: SIX_HOURS_IN_MILLISECONDS,
    ...overrides,
  });
