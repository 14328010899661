import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { makeStyles } from '@bequestinc/wui';
import { Alert, Grid, MenuItem, TextField } from '@mui/material';

import { useCountriesQuery } from 'hooks/query-hooks/address/useCountriesQuery';

import Placeholder from './pages/firm-info/PlaceHolder';

const useStyles = makeStyles()({
  fill: {
    width: '100%',
  },
});

const AddressStatesField = ({ id, disabled, states, error, fieldsUnderReview }) => {
  const { classes } = useStyles();
  const { getFieldProps, errors } = useFormikContext();

  return (
    <TextField
      select
      name={`${id}state`}
      label="State"
      className={classes.fill}
      {...getFieldProps(`${id}state`)}
      helperText={
        fieldsUnderReview.state
          ? `change to '${fieldsUnderReview.state}' is under review`
          : errors.state
      }
      disabled={disabled}
      error={Boolean(error)}
    >
      {states &&
        states.map(state => (
          <MenuItem key={state[0]} value={state[0]}>
            {state[0]}
          </MenuItem>
        ))}
    </TextField>
  );
};
AddressStatesField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  states: PropTypes.array,
  error: PropTypes.bool,
  fieldsUnderReview: PropTypes.object,
};

AddressStatesField.defaultProps = {
  id: '',
  disabled: false,
  states: [],
  error: false,
  fieldsUnderReview: {},
};

const AddressFields = ({ id, disabled, fieldsUnderReview }) => {
  const { errors, touched, getFieldProps, handleChange, setFieldValue } = useFormikContext();
  const { classes } = useStyles();
  const { data: countries = [], isLoading, error, isSuccess } = useCountriesQuery();

  if (error) return <Alert severity="error">Please Try Again Later an Error Occurred</Alert>;

  const states = countries[getFieldProps(`${id}country`).value || 'US']?.states || [];

  const isTouched = field => {
    if (field in touched) {
      return touched[field];
    }
    if (id.replace('.', '') in touched) {
      return touched[id.replace('.', '')][field];
    }
    return '';
  };

  const hasError = field => {
    if (field in errors) {
      return Boolean(errors[field]);
    }
    if (id.replace('.', '') in errors) {
      return Boolean(errors[id.replace('.', '')][field]);
    }

    return false;
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <TextField
          select
          name={`${id}country`}
          label="Country"
          className={classes.fill}
          {...getFieldProps(`${id}country`)}
          helperText={errors.country}
          disabled={disabled}
          error={hasError('country')}
          onChange={e => {
            setFieldValue(`${id}state`, '');
            handleChange(e);
          }}
        >
          {isLoading && <Placeholder />}

          {isSuccess &&
            Object.entries(countries).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value.label}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item md={8} sm={12}>
        <TextField
          name={`${id}street`}
          label="Street Address"
          className={classes.fill}
          {...getFieldProps(`${id}street`)}
          helperText={
            fieldsUnderReview.street
              ? `change to '${fieldsUnderReview.street}' is under review`
              : errors.street
          }
          disabled={disabled}
          error={hasError('street')}
        />
      </Grid>
      <Grid item md={4} sm={12}>
        <TextField
          name={`${id}street2`}
          label="Apt/Suite"
          {...getFieldProps(`${id}street2`)}
          helperText={
            fieldsUnderReview.street2
              ? `change to '${fieldsUnderReview.street2}' is under review`
              : errors.street2
          }
          disabled={disabled}
          error={hasError('street2')}
          className={classes.fill}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <TextField
          name={`${id}city`}
          label="City"
          {...getFieldProps(`${id}city`)}
          helperText={
            fieldsUnderReview.city
              ? `change to '${fieldsUnderReview.city}' is under review`
              : errors.city
          }
          disabled={disabled}
          error={hasError('city')}
          className={classes.fill}
        />
      </Grid>

      <Grid item md={2} sm={12}>
        <AddressStatesField
          id={id}
          disabled={disabled}
          states={states}
          touched={isTouched('state') && hasError('state')}
          error={hasError('state')}
          fieldsUnderReview={fieldsUnderReview}
        />
      </Grid>

      <Grid item md={4} sm={12}>
        <TextField
          name={`${id}zipCode`}
          label="Zip Code"
          {...getFieldProps(`${id}zipCode`)}
          helperText={errors.zipCode}
          disabled={disabled}
          error={hasError('zipCode')}
          className={classes.fill}
        />
      </Grid>
    </Grid>
  );
};

AddressFields.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  fieldsUnderReview: PropTypes.object,
};

AddressFields.defaultProps = {
  id: '',
  disabled: false,
  fieldsUnderReview: {},
};

export default AddressFields;
