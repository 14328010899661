import * as Yup from 'yup';

import {
  BAR_NUMBER_REQUIRED_MESSAGE,
  FIELD_REQUIRED_MESSAGE,
  PHONE_REGEX,
  REGISTER,
} from 'utils/constants';

import { addressSchema } from 'components/pages/check-eligibility/newUserSchema';

export const firmSchema = Yup.object().shape({
  name: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  mlpStatus: Yup.string().when(['action'], {
    is: action => action === REGISTER,
    then: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  }),
  email: Yup.string().email('Please enter a valid email address').required(FIELD_REQUIRED_MESSAGE),
  phone: Yup.string()
    .matches(PHONE_REGEX, 'Enter a valid 10 digit phone number')
    .required(FIELD_REQUIRED_MESSAGE),
  locationCode: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  attorneyCode: Yup.number()
    .min(9999)
    .max(100000)
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null)),
  malpracticeExpireDate: Yup.date().when(['action'], {
    is: action => action === REGISTER,
    then: Yup.date().required(FIELD_REQUIRED_MESSAGE),
    otherwise: Yup.date().nullable(true),
  }),
  chosenNoCulturalFluency: Yup.boolean(),
  culturalFluency: Yup.array().when(['chosenNoCulturalFluency', 'action'], {
    is: (chosenNoCulturalFluency, action) => !chosenNoCulturalFluency && action !== REGISTER,
    then: Yup.array().min(1, FIELD_REQUIRED_MESSAGE),
  }),
});

export const attorneySchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
    lastName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  }),
  lawSchool: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  phone: Yup.string()
    .matches(PHONE_REGEX, 'Enter a valid 10 digit phone number')
    .required(FIELD_REQUIRED_MESSAGE),
  contactEmail: Yup.string()
    .email('Please enter a valid email address')
    .required(FIELD_REQUIRED_MESSAGE),
  canTravel: Yup.boolean().required(FIELD_REQUIRED_MESSAGE),
  availableWeekends: Yup.boolean().required(FIELD_REQUIRED_MESSAGE),
  availableEvenings: Yup.boolean().required(FIELD_REQUIRED_MESSAGE),
  availableVideoConference: Yup.boolean().required(FIELD_REQUIRED_MESSAGE),
  languages: Yup.array().min(1, 'Please enter at least one language').of(Yup.string()),
  gender: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  noResponse: Yup.boolean(),
  raceOrEthnicity: Yup.array().when(['noResponse'], {
    is: false,
    then: Yup.array().min(1, FIELD_REQUIRED_MESSAGE),
    otherwise: Yup.array(),
  }),
  isPanelUser: Yup.boolean(),
  licenseInfo: Yup.array()
    .min(1, BAR_NUMBER_REQUIRED_MESSAGE)
    .of(
      Yup.object().shape({
        barNumber: Yup.string().required(FIELD_REQUIRED_MESSAGE),
        operatingState: Yup.string().required(FIELD_REQUIRED_MESSAGE),
        issuedDate: Yup.date()
          .min(
            new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
            `Please provide a date within the last 100 years.`,
          )
          .max(new Date(), `Please provide only past dates.`)
          .required(FIELD_REQUIRED_MESSAGE)
          .nullable(),
      }),
    ),
});

export const officeSchema = addressSchema.shape({
  phone: Yup.string()
    .matches(PHONE_REGEX, 'Enter a valid 10 digit phone number')
    .required(FIELD_REQUIRED_MESSAGE),
  email: Yup.string().email('Please enter a valid email address').required(FIELD_REQUIRED_MESSAGE),
  numberOfAttorneys: Yup.number().required(FIELD_REQUIRED_MESSAGE),
  serviceInfoConfirmed: Yup.boolean().when(['forConfirmationModal'], {
    is: true,
    then: Yup.boolean().isTrue('Please confirm that this information is correct before continuing'),
  }),
  addressInfoConfirmed: Yup.boolean().when(['forConfirmationModal'], {
    is: true,
    then: Yup.boolean().isTrue('Please confirm that this information is correct before continuing'),
  }),
  engagementScore: Yup.number()
    .min(0, 'Must be at least 0')
    .max(10, '10 is the highest possible score')
    .required(FIELD_REQUIRED_MESSAGE),
});

export const negotiatedFeeSchema = Yup.object().shape({
  serviceTypeId: Yup.number().when(['feeType'], {
    is: 'serviceMaximum',
    then: Yup.number().typeError().required(FIELD_REQUIRED_MESSAGE),
  }),
  feeType: Yup.string().required(FIELD_REQUIRED_MESSAGE),
  feeMaximum: Yup.number().min(1, 'Must be greater than $0'),
  offices: Yup.array().min(1, 'At least one office must be selected'),
  document: Yup.array().of(Yup.object()),
});
