import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { Modal, makeStyles } from '@bequestinc/wui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

import { FirmsQueryKey } from 'hooks/query-hooks/firms/useFirmsQuery';
import { useChangePrimaryOfficeMutation } from 'hooks/query-hooks/offices/useOfficeMutation';
import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

import {
  ADD,
  CAN_EDIT_OFFICE_ATTORNEYS_AND_SERVICES,
  INTAKE,
  OFFICE_APPROVED,
  UPDATE,
} from 'utils/constants';

import GenericFormErrorHandling from 'components/custom_fields/GenericFormErrorHandling';
import AttorneySelector from 'components/pages/firm-info/AttorneySelector';
import DeleteOfficeInfoModal from 'components/pages/firm-info/DeleteOfficeInfoModal';

const useStyles = makeStyles()({
  button: {
    width: 100,
  },
  largeButton: {
    width: 200,
  },
});

const OfficeFormButtons = ({ beginEditing, editing, enabled, action, office, isSubmitting }) => {
  const { errors } = useFormikContext();
  const { classes } = useStyles();
  const { data: user } = useCurrentUserQuery();
  const [showDeleteOfficeInfoModal, setShowDeleteOfficeInfoModal] = useState(false);
  const [showUpdateAttorneyModal, setShowUpdateAttorneyModal] = useState(false);
  const queryClient = useQueryClient();

  const buttonText = () => {
    if (action === ADD || action === UPDATE) {
      return action;
    }
    if (action === INTAKE) {
      return 'Confirm';
    }
    return 'Submit';
  };

  const handleUpdateOfficeQueryData = oldData => {
    // If user is panel rep, make primary office change reflect in cache. If user is not a panel rep, a firm update is
    // created in the backend instead of implementing the primary office change, so instead set hasPrimaryOfficeUnderReview to true in cache
    if (user.isPanelRepUser) {
      return oldData.offices.map(oldOffice =>
        oldOffice.id === office.id
          ? { ...office, isPrimaryOffice: true }
          : { ...oldOffice, isPrimaryOffice: false },
      );
    }
    return oldData.offices.map(oldOffice => ({ ...oldOffice, hasPrimaryOfficeUnderReview: true }));
  };

  const changePrimaryOfficeMutation = useChangePrimaryOfficeMutation({
    onSuccess: () => {
      queryClient.setQueryData(FirmsQueryKey.concat(office.firm), oldData => ({
        ...oldData,
        offices: handleUpdateOfficeQueryData(oldData),
      }));
    },
  });

  const changePrimaryOffice = () => {
    changePrimaryOfficeMutation.mutateAsync({ firm: office.firm, id: office.id });
  };

  const canPanelEditAttorneysAndServices = user.isPanelUser
    ? user.groups.includes(CAN_EDIT_OFFICE_ATTORNEYS_AND_SERVICES)
    : true;

  return (
    <Grid container item direction="row" justifyContent="space-between">
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        spacing={7}
        xs={10}
        alignItems="flex-start"
        wrap="noWrap"
      >
        {enabled && action === UPDATE && (
          <Grid item>
            <Button
              disabled={Object.keys(errors).length > 0 || editing}
              onClick={beginEditing}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Edit
            </Button>
          </Grid>
        )}

        {enabled && (
          <Grid item>
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              disabled={Object.keys(errors).length > 0 || !editing}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {buttonText()}
            </LoadingButton>
          </Grid>
        )}

        {enabled && action === UPDATE && canPanelEditAttorneysAndServices && (
          <Grid item>
            <Button
              variant="contained"
              className={classes.largeButton}
              onClick={() => setShowUpdateAttorneyModal(true)}
            >
              Assigned Attorneys
            </Button>
          </Grid>
        )}
        {action === UPDATE && !office.isPrimaryOffice && enabled && (
          <Grid item>
            <Button
              variant="contained"
              disabled={office.fsmState !== OFFICE_APPROVED || office.hasPrimaryOfficeUnderReview}
              className={classes.largeButton}
              onClick={changePrimaryOffice}
            >
              Make Primary Office
            </Button>
            {office.hasPrimaryOfficeUnderReview && (
              <FormHelperText error sx={{ width: 225 }}>
                Current primary office change is under review for this firm.
              </FormHelperText>
            )}
          </Grid>
        )}
      </Grid>

      {enabled && action === UPDATE && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            sx={{ maxHeight: '40px', width: 100 }}
            disabled={office.isPrimaryOffice}
            onClick={() => setShowDeleteOfficeInfoModal(true)}
          >
            Remove
          </Button>
          {office.isPrimaryOffice && (
            <FormHelperText error sx={{ width: 100 }}>
              Can't remove a primary office!
            </FormHelperText>
          )}
        </Grid>
      )}
      {action === INTAKE && <GenericFormErrorHandling />}
      {action === UPDATE && (
        <DeleteOfficeInfoModal
          officeId={office.id}
          open={showDeleteOfficeInfoModal}
          onClose={() => setShowDeleteOfficeInfoModal(false)}
          firmId={office.firm}
        />
      )}
      {action === UPDATE && (
        <Modal
          open={showUpdateAttorneyModal}
          onClose={() => setShowUpdateAttorneyModal(false)}
          title="Update Office Attorneys"
          fullWidth
        >
          <AttorneySelector office={office} />
        </Modal>
      )}
    </Grid>
  );
};

OfficeFormButtons.propTypes = {
  editing: PropTypes.bool.isRequired,
  beginEditing: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  office: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default observer(OfficeFormButtons);
