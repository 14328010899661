const formatPhoneNumber = phone => {
  if (typeof phone !== 'string') {
    return '';
  }

  const phoneNumber = phone.replace(/[^\d]/g, '');

  if (phoneNumber.length < 4) {
    return phoneNumber;
  }

  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const testCaseNumberAndLastFour = (caseNumber, lastFour) => {
  return /^\d{8}$/.test(caseNumber) && /^\d{4}$/.test(lastFour);
};

export default formatPhoneNumber;
