import { observer } from 'mobx-react';

import { useFormikContext } from 'formik';
import startCase from 'lodash/startCase';

import { Alert, AlertTitle, Grid, Typography } from '@mui/material';

const GenericFormErrorHandling = () => {
  const { errors } = useFormikContext();
  if (Object.keys(errors).length === 0) {
    return null;
  }

  const getErrorMessages = errorArray => {
    return (
      <>
        {Object.entries(errorArray).map(([key, val]) => {
          if (Array.isArray(val)) {
            return getErrorMessages(Object.assign({}, ...val));
          }
          if (typeof val === 'object') {
            return getErrorMessages(val);
          }

          return (
            <Typography key={key}>
              {startCase(key)}: {val}
            </Typography>
          );
        })}
      </>
    );
  };

  return (
    <Grid item>
      <Alert severity="error">
        <AlertTitle>
          Please fill out all required fields and correct any errors before continuing!
        </AlertTitle>
        {getErrorMessages(errors)}
      </Alert>
    </Grid>
  );
};

GenericFormErrorHandling.propTypes = {};

export default observer(GenericFormErrorHandling);
